import { Link } from 'gatsby';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100% !important;
  padding: 0;
  @media (max-width: 767px) {
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 20px 0;
    background: none;
    box-shadow: inset -11px 0 15px -11px rgb(0 0 0 / 20%);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-bottom: 30px;
  }
`;
export const LocationTable = styled(Table)`
  position: relative;
  width: 100% !important;
  margin: 0 0 40px 0;
  background: none;
  border-spacing: 0;
  border: none;
  @media (max-width: 767px) {
    width: 150% !important;
    margin-right: 30px;
  }
`;
export const THead = styled.thead`
  vertical-align: middle;
  tr th {
    color: #fff;
    padding: 15px;
    font-family: 'HelveticaNeue-Roman', sans-serif;
    font-weight: normal;
    font-size: 22px;
    line-height: 24px;
    text-align: left;
    line-height: 32px;
    background: #00a6e8;
    border-right: 0.5px solid #fff;
  }
  tr {
    th:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 6px;
    }
    th:last-child {
      border-top-left-radius: 0;
      border-bottom-right-radius: 6px;
      border-right: 0;
    }
  }
  @media (max-width: 1399px) {
    tr th {
      padding: 10px 15px;
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media (max-width: 767px) {
    tr th {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
export const TBody = styled.tbody`
  vertical-align: middle !important;
  tr td {
    background-color: #fff !important;
    box-shadow: none !important;
    padding: 18px 12px;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.6) !important;
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    @media (max-width: 1399px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

export const LinkStyle = styled(Link)`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
`;

export const LinkStyle2 = styled.a`
  display: block;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

export const Td = styled.td``;
